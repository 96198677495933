import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

import WhiteLogoUrl from '~/assets/logo-white.png';

import { Breadcrumbs } from './Breadcrumbs';
import { Container } from './Container';
// import { LanguageSelector } from './LanguageSelector';
import { Wave } from './Wave';
import { Avatar, AvatarImage } from './ui/avatar';

type HeaderProps = {
  title?: string;
  description?: string;
};

export function Header(props: HeaderProps) {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const {
    title = t('main.title') ?? 'STARRT',
    description = t('main.description') ??
      'Stroke Toolkit for Aquatic Rehabilitation and Recreation Therapy',
  } = props;

  return (
    <header>
      <div className="bg-primary">
        <Container className="navbar flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-x-2">
          <div className=" flex justify-start space-x-2 md:items-center">
            <Link className="cursor-pointer" to={`/${locale}`}>
              <Avatar className="h-16 w-16 md:h-20 md:w-20">
                <AvatarImage src={WhiteLogoUrl} />
              </Avatar>
            </Link>
            <Link to={`/${locale}`} className="flex flex-1 cursor-pointer flex-col items-start">
              <h1 className="text-2xl tracking-wider text-white md:text-4xl">{title}</h1>

              <p className="text-md tracking-wider text-white md:text-2xl">{description}</p>
            </Link>
          </div>
          {/* <div className="flex-none">
            <LanguageSelector />
          </div> */}
        </Container>

        <Wave position="bottom" />
      </div>
      <div className="md:text-lg">
        <Container className="!py-0">
          <Breadcrumbs variant="primary" size="xl" />
        </Container>
      </div>
    </header>
  );
}
