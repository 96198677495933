import { cn } from '~/utils/cn';

type ContainerProps = {
  className?: string;
};

export function Container({ children, className }: React.PropsWithChildren<ContainerProps>) {
  return (
    <div className={cn('mx-auto w-full max-w-screen-2xl border-none p-4 md:p-6', className)}>
      {children}
    </div>
  );
}
