import LeafCIHRUrl from '~/assets/leaf-cihr-colour-portrait-en.jpg';
import SunnybrookUrl from '~/assets/sunnybrook-st-johns-transparent.png';

import { Container } from './Container';
import { Wave } from './Wave';

type FooterProps = {
  Links?: JSX.Element;
};

export function Footer({ Links }: FooterProps) {
  const hasLinks = !!Links;
  return (
    <footer className="bg-white">
      <Wave position="top" />

      <Container>
        {hasLinks ? <div className="mb-4 flex items-center justify-center">{Links}</div> : null}
        <div className="flex items-center justify-between">
          <a
            aria-label="Go to Canadian Institutes of Health Research website"
            href="https://cihr-irsc.gc.ca/"
            target="_blank"
            rel="noreferrer">
            <img
              src={LeafCIHRUrl}
              className="h-9 md:h-12"
              alt="Canadian Institutes of Health Research"
            />
          </a>

          <a
            aria-label="Go to Sunnybrook St. John's Rehab website"
            href="https://sunnybrook.ca/content/?page=st-johns-rehab"
            target="_blank"
            rel="noreferrer">
            <img src={SunnybrookUrl} className="h-9 md:h-12" alt="Sunnybrook St. John's Rehab" />
          </a>
        </div>
      </Container>
    </footer>
  );
}
