import { useFetcher } from '@remix-run/react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from '~/components/ui/alert-dialog';
import { Button } from '~/components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '~/components/ui/drawer';
import { ScrollArea } from '~/components/ui/scroll-area';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { parseHtml } from '~/utils/parse-html';

type DisclaimerProps = {
  title: string;
  content: string;
  disclaimerAccepted: boolean;
};

export function Disclaimer({ title, content, disclaimerAccepted }: DisclaimerProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(!disclaimerAccepted);
  const isDesktop = useMediaQuery('(min-width: 768px)');

  if (isDesktop) {
    return (
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription className="text-md prose" asChild>
              <ScrollArea className="max-h-[450px]">{parseHtml(content)}</ScrollArea>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel asChild>
              <Button type="reset" variant="outline">
                {t('disclaimer.cancel')}
              </Button>
            </AlertDialogCancel>
            <AlertDialogAction asChild>
              <AcceptButton setOpen={setOpen} />
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerContent>
        <DrawerHeader className="max-h-[450px] overflow-y-scroll text-left">
          <DrawerTitle>{title}</DrawerTitle>
          <DrawerDescription className="text-md prose" asChild>
            <div>{parseHtml(content)}</div>
          </DrawerDescription>
        </DrawerHeader>
        <DrawerFooter className="shadow-t-xl pt-4">
          <DrawerClose asChild>
            <Button type="reset" variant="outline">
              {t('disclaimer.cancel')}
            </Button>
          </DrawerClose>
          <AcceptButton className="w-full" setOpen={setOpen} />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

function AcceptButton({
  className,
  setOpen,
}: {
  className?: string;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const disclaimerFetcher = useFetcher();

  return (
    <disclaimerFetcher.Form method="post" action="/accept-disclaimer">
      <Button
        type="submit"
        name="accept-disclaimer"
        value="true"
        className={className}
        onClick={() => setOpen?.(false)}
      >
        {t('disclaimer.accept')}
      </Button>
    </disclaimerFetcher.Form>
  );
}
