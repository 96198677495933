import type { LoaderFunctionArgs } from '@remix-run/node';
import type { PropsWithChildren } from 'react';

import { json } from '@remix-run/node';
import { Link, Outlet, UIMatch, useLoaderData, useMatches } from '@remix-run/react';
import { DotIcon } from 'lucide-react';
import React from 'react';
import { ClientOnly } from 'remix-utils/client-only';

import { Container } from '~/components/Container';
import { Disclaimer } from '~/components/Disclaimer';
import { GeneralErrorBoundary } from '~/components/ErrorBoundary';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { useTypedRouteLoaderData } from '~/hooks/useTypedRouteLoaderData';
import { i18next } from '~/lib/i18n/server';
import { wordpress } from '~/services/wordpress';
import { cleanUpUrl } from '~/utils/cleanup-url';
import { cn } from '~/utils/cn';
import { cookies } from '~/utils/cookies';

type LayoutProps = PropsWithChildren & {
  skipToContent?: string;
  isMap?: boolean;
  title?: string;
  description?: string;
};

export const handle = {
  breadcrumb: (match: UIMatch<typeof loader>) => <span>{match?.data?.crumb}</span>,
  // translations: (match: RouteMatch) => match?.data?.translations,
};

export async function loader({ params, request }: LoaderFunctionArgs) {
  const locale = params.locale ?? 'en';
  const cookieHeader = request.headers.get('Cookie');
  const cookie = (await cookies.disclaimer.parse(cookieHeader)) || {};
  const t = await i18next.getFixedT(locale);
  const { page } = await wordpress.GetPage({ language: locale, slug: 'home' });

  if (!page) throw new Response('Not Found', { status: 404 });

  const { page: disclaimer } = await wordpress.GetPage({ language: locale, slug: 'disclaimer' });
  const footerMenu = await wordpress.GetMenuBySlug({ slug: `footer-${locale}` });

  const footerLinks = footerMenu.menus?.nodes
    .shift()
    ?.menuItems?.nodes.map(({ id, label, path }) => ({
      id,
      label,
      path: cleanUpUrl(locale, path),
    }));

  return json({
    locale,
    crumb: page?.title ?? t('homepage.breadcrumb') ?? 'Home',
    // // translations: page?.translated?.map((translation) => ({
    // //   [`${translation?.languageCode}`]: translation?.languageCode,
    // // })),
    skipToContent: t('header.skipToContent') ?? 'Skip to content',
    footerLinks,
    disclaimerAccepted: !!cookie.acceptDisclaimer,
    disclaimer,
  });
}

export default function Index() {
  const { disclaimer, skipToContent, disclaimerAccepted } = useLoaderData<typeof loader>();
  // const [openDisclaimer, setOpenDisclaimer] = useState(!disclaimerAccepted);
  const rootData = useTypedRouteLoaderData('root');
  // const { t } = useTranslation();
  // const disclaimerFetcher = useFetcher();
  const matches = useMatches() as UIMatch<unknown, { isMap?: boolean }>[];
  const isMap = matches.filter((match) => match?.handle?.isMap).length > 0;

  return (
    <>
      <div className="grid min-h-screen w-full grid-cols-[100%] grid-rows-[auto_1fr_auto] bg-white">
        <Layout
          title={rootData?.title}
          description={rootData?.description}
          skipToContent={skipToContent}
          isMap={isMap}
        >
          <Outlet />
        </Layout>
        <Footer Links={<FooterLinks />} />
      </div>

      <ClientOnly>
        {() => (
          <Disclaimer
            title={`${disclaimer?.title}`}
            content={`${disclaimer?.content}`}
            disclaimerAccepted={disclaimerAccepted}
          />
        )}
      </ClientOnly>
    </>
  );
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}

function Layout({
  title,
  description,
  skipToContent = 'Skip to content',
  isMap,
  children,
}: LayoutProps) {
  return (
    <>
      <a
        role="button"
        href="#main-content"
        className="absolute -top-4 -m-1 h-1 w-auto transform overflow-hidden border-0 border-none bg-primary px-4 py-2 font-semibold text-white transition-transform duration-200 focus:absolute focus:left-2 focus:top-1 focus:z-50 focus:box-border focus:h-auto focus:translate-y-3"
      >
        {skipToContent}
      </a>
      <Header title={title} description={description} />

      <main id="main-content" className="relative h-full">
        <Container
          className={cn('h-full', {
            prose: !isMap,
            'prose-lg': !isMap,
          })}
        >
          {children}
        </Container>
      </main>
    </>
  );
}

function FooterLinks({ className }: { className?: string }) {
  const { footerLinks } = useLoaderData<typeof loader>();

  return (
    <ul className="flex items-center whitespace-nowrap">
      {footerLinks?.map((link, index) => (
        <React.Fragment key={link.id}>
          <li>{index !== 0 ? <DotIcon className="h-5 w-5 text-gray-400" /> : null}</li>
          <li className="flex items-center">
            <Link
              className={cn(
                'text-primary underline hover:text-primary-dark hover:no-underline',
                className
              )}
              to={link.path}
            >
              {link.label}
            </Link>
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
}
